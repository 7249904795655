export function leaveConfirmation(show: boolean, next: () => void): void {
    if (show) {
        const confirm = window.confirm(
            "There are unsaved changes, are you sure to leave this page"
        );
        if (confirm) {
            next();
        }
    } else {
        next();
    }
}

let isUnsavedChangesEnabled = false; // Tracks whether the handler is active

export function enableLeaveConfirmation(isEdited: () => boolean): void {
    if (!isUnsavedChangesEnabled) {
        window.onbeforeunload = (event: BeforeUnloadEvent) => {
            if (isEdited()) {
                const message =
                    "There are unsaved changes, are you sure you want to leave this page?";
                event.preventDefault();
                return message;
            }
        };
        isUnsavedChangesEnabled = true;
    }
}

export function disableLeaveConfirmation(): void {
    if (isUnsavedChangesEnabled) {
        window.onbeforeunload = null;
        isUnsavedChangesEnabled = false;
    }
}
